import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import {RefreshIcon, InformationIcon} from "../../media/svgIcons";
import {Button} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {CustomLoader} from './FallBackComponenets';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles(() => ({
    root: {
      padding: '24px',
    },
    button: {
      "&:disabled": {
        backgroundColor: "#DFE2DE"
      }
    },
  }));

export default function AttributesHeader(props){

    const classes = useStyles();
    const tenantId = decodeURIComponent(localStorage.getItem('b2bTenantId'));
    const token = localStorage.getItem("b2bToken");
    const taskObj = {
        'Accounts':'UPDATE_ACCOUNT_ATTRIBUTE_VALUES',
        'Contacts':'UPDATE_CONTACT_ATTRIBUTE_VALUES',
        'Activities':'UPDATE_ACTIVITY_ATTRIBUTES_VALUES',
        'Leads':'UPDATE_LEAD_ATTRIBUTE_VALUES',
        'Opportunities':'UPDATE_OPPORTUNITY_ATTRIBUTES_VALUES'
    }

    const [refreshEnable, setRefreshEnable] = React.useState(false);
    const [showProgressText, setShowProgressText] = React.useState(false);
    const [showCompletedText, setShowCompletedText] = React.useState(false);
    const [completedTime, setCompletedTime] = React.useState('');
    const [other, setOther] = React.useState(false);
    const [jobIdObj, setJobIdObj] = React.useState({'Accounts':{jobId:"", status:""}, 'Contacts':{jobId:"", status:""},
    'Activities':{jobId:"", status:""},
   'Leads':{jobId:"", status:""}, 'Opportunities':{jobId:"", status:""}});

    useEffect(() => {
        console.log("[props.attrType->",props.attrType);
        
        const key = Object.keys(taskObj);
        if(props.attrType && key.includes(props.attrType))
          functionCheckJobStatus()
      }, [props.attrType]);
    
      function getCompletedDate(isoString){
        let date = new Date(isoString);
        
        let year = date.getUTCFullYear();
        let month = String(date.getUTCMonth() + 1).padStart(2, '0');
        let day = String(date.getUTCDate()).padStart(2, '0');
        
        let hours = String(date.getUTCHours()).padStart(2, '0');
        let minutes = String(date.getUTCMinutes()).padStart(2, '0');
        
        let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
        
        console.log("formattedDate=>",formattedDate); 
        return formattedDate;
      }


      function functionCheckJobStatus(){
        console.log("tenantId->",decodeURIComponent(props.tenantId));
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" ,
          "Cookie" : token
        },
          credentials: "include",
        };
        
        fetch(`${window._env_.METADATA_API}/metadata-create/job-status?tenantId=${decodeURIComponent(props.tenantId)}&task=${taskObj[props.attrType]}&jobId=${jobIdObj[props.attrType]?.jobId}`,
         requestOptions)
        .then((response) => 
        
          response.json()
        )
        .then((val) => {
          console.log("json-",val);
        //   setRefreshEnable(true);
          if(val?.data?.status === 'COMPLETED' ) {
            console.log("When the job is Completed");
            setCompletedTime(getCompletedDate(val?.data?.updatedAt));
            setRefreshEnable(true);
            setShowCompletedText(true);
            setShowProgressText(false);
            }else if(val?.data?.status === 'FAILED' ) {
              console.log("When the job is failed");
              setRefreshEnable(true);
              setShowProgressText(false);
              }
            else if(val?.data === false){
              console.log("When data is false");
              setRefreshEnable(true);
              setShowProgressText(false);
            }else{
              console.log("No condition statisfied");
              setRefreshEnable(false);
              setShowProgressText(true);
            }
            setJobIdObj((pre) => ({
              ...pre,
              attrType:{
                jobId:jobIdObj[props.attrType]?.jobId,
                status:val?.data?.status
              }
      
            }))
        })
        .catch((err) => {
          console.error(err);
        });
      }
    
    function handleRefreshClick(){
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" ,
          "Cookie" : token
        },
          body: JSON.stringify({
            "tenantId":localStorage.getItem("b2bTenantId"),
            "task": taskObj[props.attrType]
          }),
          credentials: "include",
        };
    
        fetch(`${window._env_.METADATA_API}/metadata-create/new/user/job?tenantId=${decodeURIComponent(props.tenantId)}&task=${taskObj[props.attrType]}`,
         requestOptions)
        .then((response) => 
          response.json()
        )
        .then((val) => {
          console.log("job created value",val);
          setRefreshEnable(false);
          setShowProgressText(true);
          setJobIdObj((pre) => ({
            ...pre,
            [props.attrType]:{
              jobId:val?.jobId,
              status:val?.status
            }
    
          }))
          
        })
        .catch((err) => {
          console.error(err);
        });
        
      }
    return (
        <div style={{display:'flex', justifyContent:"space-between"}}>
        <Typography  className="page-heading" style={{ 
        fontFamily: "Inter",
        fontWeight: '700',
        fontSize: '20px',
        marginLeft:"48px",
        lineHeight: '24px',
        color: 'rgb(89, 89, 89)',
        paddingLeft:'10px'
        }}>Attribute Values Setup</Typography>  

        <div style={{display:"flex", flexDirection:"row", gap:"10px"}}>
        <p style={{marginTop:"5px", display:"flex", gap:"10px", visibility: !showProgressText && !showCompletedText ? "hidden": "initial"}} >
        { 
        
        showProgressText? 
        
        <Typography  
        className="page-heading" 

        style={{ 
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "14.52px",
            textSlign: "left",
            marginTop:"2px"

        }}>Refresh in progress. This may take a while</Typography>
        :
        showCompletedText ? 
        <Typography  
        className="page-heading" 

        style={{ 
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "14.52px",
            textSlign: "left",
            marginTop:"2px"

        }}>Last Refreshed at {completedTime}</Typography> 
        : ""
    }
           
            {/* <CustomLoader/> */}
        </p>
        {/* <span style={{display:'flex', gap:"10px"}}> */}
        <Button 
        className= {classes.button}
        disabled = {!refreshEnable}
        onClick={handleRefreshClick}
        style={{
                        height: "32px",
                        padding:"8px, 56px, 8px, 56px",
                        borderRadius:"12px",
                        border:"1px solid #DFE2DE",
                        gap: "4px",
                        backgroundColor: refreshEnable ? "#ffffff" : "#DFE2DE"
                        
        }}>
            <span style={{display:"flex", padding:"6px"}}>

            
            {showProgressText ? <CustomLoader/> : <RefreshIcon/>}
            <Typography  className="page-heading" style={{ 
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "14.52px",
                    textSlign: "left",
                    marginLeft:"4px",
                    textTransform: "none",
                    
        }}>Refresh All</Typography></span> 
        </Button>
        <Tooltip title={"Click to refresh values for all attributes in the selected attribute category"} arrow>
                <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginBottom:"1px",marginLeft:"-6px" }}>
                  <InformationIcon />
                </div>
        </Tooltip>
        {/* </span> */}
        </div>
    </div>
    );
}