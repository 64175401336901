import Avatar from "@mui/material/Avatar";
import "./index.css"

export const User = (props) => {
  const { name, email } = props;

  return (
    <>
      <div className="user-body">
        <UserAvatar name={name} />
        <div className="user-body-content">
          <div className="name">{name}</div>
          <div className="email">{email}</div>
        </div>
      </div>
    </>
  );
};

export const UserAvatar = ({ name }) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")?.[0]?.[0]?.toUpperCase() || ""}${name.split(" ")?.[1]?.[0]?.toUpperCase() ?? ""}`,
    };
  }

  return (
    <>
     <div style={{ position: "relative", height: "32px", width: "32px" }}>
      <Avatar style={{ height: "32px", width: "32px", fontSize: "14px", fontWeight: "600" }} {...stringAvatar(name)} />
      <div style={{ position: "absolute", bottom: "0", left: "24px", height: "6px", width: "6px", borderRadius: "50%", backgroundColor: "#78C51C", border: "1px solid white" }}></div>
     </div>
    </>
  );
}
