import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "./dialogStyles.css";

export const AlertDialog = (props) => {
  const { open, setOpen, setLogoutDialog, logoutDialogHandler } = props;
  const [timer, setTimer] = useState("--:--");

  setInterval(() => {
    const lastActivity = localStorage.getItem("lastActivityTime");
    const now = new Date().getTime() / 1000;
    const totalRemainTime = Math.floor(300 - (now - lastActivity));
    /* if time is negative then logout pop-up will open */
    if(totalRemainTime < 0) {
      setLogoutDialog(true);
    }
    const minutes = Math.floor(totalRemainTime / 60);
    const seconds = Math.floor(totalRemainTime % 60);
    setTimer(String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0'));
  }, 1000);

  return (
    <>
      <Dialog className="logout-dialog" open={open} onClose={logoutDialogHandler} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <div id="alert-dialog-title" className="header-content">{`Session expiring soon (${timer})`}</div>
          <hr />
          <div id="alert-dialog-description" className="description-content">
            For security reasons, you will be automatically logged out due to inactivity. Click on <b>Continue Session&nbsp;</b> below if you wish to continue.
          </div>
          <hr />
        </DialogContent>
        <DialogActions>
          <div onClick={logoutDialogHandler} className="log-out-now">
            Logout
          </div>
          <div onClick={(event, reason) => logoutDialogHandler(event, reason, true)} className="continue-session" style={{ width: "150px" }}>
            Continue Session
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
