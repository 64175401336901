import { render } from '@testing-library/react';
import axios from 'axios'
import React , {useState} from 'react'
import Modal from 'react-modal'




export default {
    login,
    logout,
  };

  
const instance = axios.create({
    baseURL: `${window._env_.AUTH_URL}`,
    headers: {      
      "Content-Type": "application/json",
      accept: "*/*",
    },
    withCredentials: true
  });
 


  // function login(token) {
  //   return new Promise((resolve, reject) => {
  //   instance
  //       .post("users/googleoauth", JSON.stringify(token))
  //       .then((response) => {
  //         return resolve(response.data);
  //       })
  //       .catch((error) => {
  //         return reject(error);
  //       });
  //   });
  // }

  function login(token, tenantId, type, ip) {
    return new Promise((resolve, reject) => {
    instance
        .post("users/googleoauth", JSON.stringify(token), tenantId, type, ip)
        .then((response) => {
          if(response?.data){
            if(response?.data?.token&&response?.data?.tenantId){
          console.log("cookie log ui",response?.data);
          localStorage.setItem("b2bToken", response?.data?.token);
          localStorage.setItem("b2bTenantId", response?.data?.tenantId);
            }
          console.log("cookie log ui after setting the local setting",response?.data);
          return resolve(response.data);
          }
        })
        .catch((error) => {
          return reject(error);
        });
    });
  }

  function logout()
  { 
    localStorage.removeItem("b2bToken");
    localStorage.removeItem("b2bPermission");
    localStorage.removeItem("tenantImage");
    localStorage.removeItem("lastActivityTime");

    fetch(`${window._env_.AUTH_URL}/users/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.success) {
          localStorage.clear();
          window.location.href = window._env_.FRONT_URL;
        }
      });

  }

  // function SelectTenantName(props){
  //   console.log('INSIDE ANOTHER FUNCT')
  //   return(
  //     <TenantListsPopup />

  //   )
   

   
  // }
  