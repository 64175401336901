import React,{useEffect, useState} from "react";
import {useLocation } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Redirect() {

    const [isLoading, setIsLoading] = useState(false)
    
    let query = useQuery();
    useEffect(() => {
       

        let code = query.get("code") || "";

        
        localStorage.setItem('b2_code_sent', 'success');
      // api should be called
      
    });
    return (
      <React.Fragment>
          {isLoading?
          <div style={{position: "absolute", top: "50%", left: "50%", translate: "transform(-50%,-50%)" }}><CircularProgress style={{color:"#21DA9C"}} /></div>
          :<div style={{textAlign: "center", paddingTop: "45vh"}}><p>MailChimp is authenticated. You can close this tab</p><p>- Team Bamboobox</p></div>}

      </React.Fragment>
    );
  }

  export default Redirect;