import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Alert from "@mui/material/Alert";
import "../Header/toast.css"
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import TableRow from '@mui/material/TableRow';
import Switch from "@mui/material/Switch";
import Paper from '@mui/material/Paper';
import { styled } from "@mui/material/styles";
import {DeleteIcon} from "../../media/svgIcons";
// import TablePagination from '@mui/material/TablePagination';
import Footer from './Footer';
import TablePagination from './tablePagination';
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    marginRight: "-8px",
    "& .MuiSwitch-track": {
      borderRadius: 8,
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="white" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      borderRadius: 3,
      boxShadow: "none",
      color: "white",
      width: 13,
      height: 14,
      marginLeft: 4.2,
      marginTop: 3,
    },
    "& .Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#78C51C !important",
      opacity: "1 !important",
    },
  }));



export default function AttributesTable(props) {

  console.log("props.rows length",props.rows.length)
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: true,
    newestOnTop: true,
  });


    const [startPage, setStartPage] = React.useState(0);
    const [endPage, setEndPage] = React.useState(15);
    const [renderedRows, setRenderedRows] = React.useState(props.rows);
    
    function handleVisibility(value) {
        console.log("visibilty on change");
        props.setRows(props.rows.map(e => {
            if (e.attributeValue === value) {
              e.enabled = !e.enabled
              const requestOptions = {
                method: "POST",
                headers: { "Content-Type": "application/json" ,
                "Cookie" : localStorage.getItem("b2bToken")
              },
                body: JSON.stringify({
                  "tableName":props.body.Category,
                  "columnName":props.body.SubAttribute ? props.body.SubAttribute : props.body.Attribute,
                  "source":props.body.Source,
                  "activityType":props.body.ActivityType,
                  "attributeValue":value,
                  enabled : e.enabled
                }),
                credentials: "include",
              };
        
              fetch(`${window._env_.METADATA_API}/attribute-setup/v2/updateAttributeData/${localStorage.getItem('b2bTenantId')}`,
               requestOptions)
              .then((response) => 
              
                response.json()
              )
              .then((val) => {
                console.log("json-",val);
              })
              .catch((err) => {
                console.error(err);
              });
             }
             return e
          }))
    
    }

    useEffect(() => {
      console.log(startPage,"  ",endPage);
      console.log("renderedRows->",renderedRows);
      setRenderedRows(props.rows.slice(startPage, endPage));
    }, [startPage, endPage, props.rows.length])


  const handleDeletion = (row) => {
    console.log("row->",row);
    const newArr = props.rows.filter(obj => obj.attributeValue !== row.attributeValue);
    props.setRows(newArr);
    console.log("deleted", row);
    toast.error(<Alert severity="error">{"Attribute value deleted!"}</Alert>, {
      closeOnClick: true,
      autoClose: true,
      closeButton: <CloseIcon style={{ width:"18px"}}/>,
      newestOnTop: true,
    });

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" ,
      "Cookie" : localStorage.getItem("b2bToken")
    },
      body: JSON.stringify({
        "tableName":props.body.Category,
        "columnName":props.body.SubAttribute ? props.body.SubAttribute : props.body.Attribute,
        "source":props.body.Source,
        "activityType":props.body.ActivityType,
        "attributeValue":row.attributeValue
      }),
      credentials: "include",
    };

    fetch(`${window._env_.METADATA_API}/attribute-setup/v2/deleteAttributeData/${localStorage.getItem('b2bTenantId')}`,
     requestOptions)
    .then((response) => 
    
      response.json()
    )
    .then((val) => {
      console.log("json-",val);
    })
    .catch((err) => {
      console.error(err);
    });

  }

  return (
    <>
      <TableContainer component={Paper} sx={{ width:"1066px" , marginLeft:"48px", maxHeight:"648px", marginTop:"4px"}}>
          
        <Table sx={{ width:"1066px" }} aria-label="simple table">
          {/* <TableHead sx = {{width:"0px", backgroundColor:"#F9F9F9"}}>
            <TableRow>
              <TableCell>Values</TableCell>
              
              <TableCell align="center">Created On</TableCell>
              <TableCell align="right">Visibility</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {
            renderedRows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" className = 'MuiTableCell-firstColumn' style={{height:"10px",font:"Inter",fontWeight:500,
                size:"14px",lineHeight:"16.94px",padding:"17px 0px 12px 14px !important",width:"216px", boxSizing:'border-box',
                 }}>
                <div style={{width: "fit-content",display:"flex"}}>

                <Tooltip title={row.attributeValue}>
                <div style={{ display: "flex", alignItems: "center", cursor: "pointer", marginBottom:"8px" }}>
                <p style={{overflow: 'hidden', display: "inline-block",
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    maxWidth: "160px"}}> 
                  {row.attributeValue}</p>
                </div>
                </Tooltip>
                
                  <p style={{
                    maxWidthwidth:"31px", 
                    maxHeight:"15px",
                    padding:"2px 4px 2px 4px", background: "#F7F7F7",
                      borderRadius:"4px",
                      marginLeft:"8px",
                      fontFamily: "Inter",
                      fontSize: "10px",
                      fontWeight: "500",
                      lineHeight: "12.1px",
                      textAlign: "left",
                      }}>{!row.userGenerated ? "System" : "User"}</p>
                </div>
                </TableCell>
                <TableCell align="center" className = 'MuiTableCell-secondColumn'>{new Date(row.updatedAt).toISOString()}</TableCell>
                <TableCell align="center" className = 'MuiTableCell-thirdColumn'>
                  <Android12Switch checked={row?.enabled} onChange={() => handleVisibility(row?.attributeValue)}/>
                  </TableCell>
              {row.userGenerated ? <TableCell align="right" className = 'MuiTableCell-forthColumn'>
              <div style={{ cursor: 'pointer' }} onClick={() => handleDeletion(row)}><DeleteIcon fontSize="large"/></div>
              </TableCell> : <TableCell><div style={{ height: "16px", width: "16px" }}></div></TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {
        parseInt(props.rows.length) >= 15 ? 
        <TablePagination rows={props.rows} setStartPage = {setStartPage} setEndPage = {setEndPage} startPage={startPage} endPage = {endPage} />
        : 
        <></>
      }
      
    </>
  );
}
