import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, HashRouter as Router } from "react-router-dom";
import './index.css';
// import MyComponent from './Components/dashboard';
import reportWebVitals from './reportWebVitals';
import RoutesManager from './routes'


function RApp() {
  
  return (
   
    <React.StrictMode>
      <Router>
        <RoutesManager />
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(
  
    <RApp />
  ,
  document.getElementById('root')
);

reportWebVitals();
