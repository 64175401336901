import React from 'react';
import { InputBase, Paper, IconButton, Avatar, Button, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { NoAccess } from '../NoAccess';

function ZohoSetUp() {
  const theme = createMuiTheme({
    palette:{
      secondary:{
        main:"#1f454f"
      }
    }
  })
    function addSource() {
       const scope = "ZohoCRM.users.ALL,ZohoCRM.bulk.read,ZohoCRM.modules.leads.READ,ZohoCRM.modules.accounts.READ,ZohoCRM.modules.contacts.READ,ZohoCRM.modules.ALL";
       let url = `https://accounts.zoho.com/oauth/v2/auth?scope=${scope}&client_id=${window._env_.ZOHO_CLIENT_ID}&response_type=code&access_type=offline&prompt=consent&redirect_uri=${window._env_.ZOHO_CALLBACK_URL}`;
      //  url = encodeURIComponent(url);
       window.open(url, '_self');
    }
    let permissions = JSON.parse(localStorage.getItem("b2Permissions")) || [];
  const permissionCheck=()=>{
      const permissionsOfUser = permissions.map((obj)=>obj.resourceName.toLowerCase());
      if(permissionsOfUser.includes("attribute_setup")){
        return true;
      }
      return false;
  }
    return (
      <>
        {permissionCheck()?<ThemeProvider theme={theme}>
        <div className="zoho-position">
          <Button size="small" variant="contained" onClick={addSource} color="secondary" style={{marginLeft:"20px"}}>Authenticate Zoho</Button>
        </div>
      </ThemeProvider>:<NoAccess />}
      </>
      
      // onClick={logOut}
    );
}

export default ZohoSetUp;

// https://zohosync1.loca.lt/zoho/auth/callback?code=1000.668784319273529f8a939fc2ccadb4fb.2637f3d33d4e22304fe1ec66d104343b&location=us&accounts-server=https%3A%2F%2Faccounts.zoho.com&