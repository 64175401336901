import Avatar from "@mui/material/Avatar";

export const IcpAvatar = ({ name, fullName = "" }) => {
  function stringToColor(string) {
    const bgArray = ["#016B40", "#1ABF6E", "#92CC38", "#C2AE46", "#FDC731", "#FAA544", "#FC693E", "#FDA6A5", "#FC706D", "#D83545", "#B0344C", "#DE2268", "#FD57BA", "#F899EC", "#9247D3", "#6D41C6", "#72357C", "#391485", "#4D4DD5", "#224583", "#518EF6", "#1471A9", "#60C3FB", "#6BA4C3", "#90A3B4", "#BCBCBC", "#6A6A6A", "#2D2D2D", "#734B40", "#DE7CB3", "#B39C81", "#99DDF3", "#C58979", "#2560D5", "#1A4F57", "#B59EF4", "#A0B5E2", "#938EAF", "#4C3737"];
    
    let sum = 0;
    for (let i = 0; i < string.length; i++) {
        sum += string.charCodeAt(i);
    }
    const index = sum % bgArray.length;
    return bgArray[index];
  }
  const getContrastingColor = (hexColor) => {
    const threshold = 130; // Adjust this threshold as needed
    const [r, g, b] = hexColor.match(/\w\w/g).map((x) => parseInt(x, 16));
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > threshold ? "#000000" : "#ffffff"; // Choose black or white as contrasting color
  };
  function stringAvatar(name) {
    const background = stringToColor(fullName);
    const color = getContrastingColor(background);
    return {
      sx: {
        bgcolor: name === "__" ? "#EBEDE8" : background,
        color: color,
      },
      children: name.toUpperCase(),
    };
  }

  return (
    <>
      <Avatar style={{ fontSize: "10px", fontWeight: "700", fontFamily: "Inter", height: "24px", width: "24px", lineHeight: "0" }} {...stringAvatar(name)} />
    </>
  );
};

export const IcpFullAvatar = ({ name, size }) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name,
    };
  }

  return (
    <>
      <Avatar style={{ fontSize: "9px", height: size || "24px", width: size || "24px" }} {...stringAvatar(name)} />
    </>
  );
};

export const WarningAmberIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size || "18"} height={size || "18"} viewBox="0 0 18 18" fill="none">
      <path
        d="M2.04408 15.75C1.90658 15.75 1.78158 15.7156 1.66908 15.6469C1.55658 15.5781 1.46908 15.4875 1.40658 15.375C1.34408 15.2625 1.30971 15.1406 1.30346 15.0094C1.29721 14.8781 1.33158 14.75 1.40658 14.625L8.34408 2.625C8.41908 2.5 8.51596 2.40625 8.63471 2.34375C8.75346 2.28125 8.87533 2.25 9.00033 2.25C9.12533 2.25 9.24721 2.28125 9.36596 2.34375C9.48471 2.40625 9.58158 2.5 9.65658 2.625L16.5941 14.625C16.6691 14.75 16.7035 14.8781 16.6972 15.0094C16.691 15.1406 16.6566 15.2625 16.5941 15.375C16.5316 15.4875 16.4441 15.5781 16.3316 15.6469C16.2191 15.7156 16.0941 15.75 15.9566 15.75H2.04408ZM9.00033 13.5C9.21283 13.5 9.39096 13.4281 9.53471 13.2844C9.67846 13.1406 9.75033 12.9625 9.75033 12.75C9.75033 12.5375 9.67846 12.3594 9.53471 12.2156C9.39096 12.0719 9.21283 12 9.00033 12C8.78783 12 8.60971 12.0719 8.46596 12.2156C8.32221 12.3594 8.25033 12.5375 8.25033 12.75C8.25033 12.9625 8.32221 13.1406 8.46596 13.2844C8.60971 13.4281 8.78783 13.5 9.00033 13.5ZM9.00033 11.25C9.21283 11.25 9.39096 11.1781 9.53471 11.0344C9.67846 10.8906 9.75033 10.7125 9.75033 10.5V8.25C9.75033 8.0375 9.67846 7.85938 9.53471 7.71563C9.39096 7.57188 9.21283 7.5 9.00033 7.5C8.78783 7.5 8.60971 7.57188 8.46596 7.71563C8.32221 7.85938 8.25033 8.0375 8.25033 8.25V10.5C8.25033 10.7125 8.32221 10.8906 8.46596 11.0344C8.60971 11.1781 8.78783 11.25 9.00033 11.25Z"
        fill="#FAA544"
      />
    </svg>
  );
};
