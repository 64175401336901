import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import  {makeStyles} from '@mui/styles';
import jwt_decode from "jwt-decode";
import './icpSelector.css'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { IcpAvatar, WarningAmberIcon } from './icpAvatar';


const useStyles = makeStyles({
  select: {
    "& .MuiSelect-select": {
      padding: "0",
      height: "40px !important",
      backgroundColor: "transparent !important",
    },
    "& .MuiSvgIcon-root": {
      color: "#FFB026",
    },
    "& .MuiSelect-iconOpen": {
      marginRight: "10px",
    },
  },
  root:{
      color:"#8A8A8A"
  },
  formControl: {
      margin: "2px",
      maxWidth: "13vw",
  },
  selectEmpty: {
      marginTop: "2px"
  },
  icon: {
      fill: '#21DA8C',
      fontSize: '28px'
  },
selected: {
  borderRadius:"16px",
  background:"#d2f8d2 !important" 
},
iconInDD:{
  width: "16px !important",
},
rootInDD:{
  color:"#000000"
},
  "selected-body": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    padding: "8px",
    fontSize: "14px",
    fontWeight: "600",
  },
  "icp-popper-menu": {
    left: "8px !important",
    marginTop: "8px",
      width: "calc(270px - 16px)",
    maxHeight: "224px !important",
    minWidth: "auto !important",
    border: "1px solid #E5E5E5",
    borderRadius: "8px !important",
    boxShadow: "0px 2px 8.4px -2px #45454540 !important",
    "&::-webkit-scrollbar": {
      margin: "10px 0",
      width: "3px",
      backgroundColor: "transparent !important",
    },
    "&::-webkit-scrollbar-track": {
      margin: "16px 0",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D9D9D9"
    },
    "& ul": {
      padding: "4px !important",
      width: "100% !important",
      borderRadius: "8px !important",
      display: "grid",
      gap: "4px",
      boxSizing: "border-box",
      "& .Mui-selected": {
        backgroundColor: "#F0F2EE !important",
      },
      "& li": {
        padding: "10px 6px",
        borderRadius: "6px",
        display: "grid",
        gap: "4px",
        gridTemplateColumns: "24px auto 18px",
        width: "250px",
      }
    }
  }
});

const IcpSelector = (props) => {
  const b2ScoringApi = `${window._env_.SCORING_API}`;
  // const [icpName, setIcpName] = React.useState('');
  
  const [anchor,setAnchor] = React.useState();
  const [open,setOpen] = React.useState(false);
  const [lastUsedIcp,setLastUsedIcp]=React.useState("");
  // const userDetails = useAuthState();
  // const tenantId = userDetails.user.tenantId;
  let userData = {};
  let prev_token = "";

  console.log("user management ui ICP selector", localStorage.getItem('b2bToken'));

  if (localStorage.getItem('b2bToken')){
    try {
      
      userData = jwt_decode(localStorage.getItem('b2bToken'));
    } catch (error) {
     console.log(error) 
    }
  }
  const tenantId = userData?.tenantId || '';
  const userUuid = userData?.userUuid || '';

  console.log("user management ui ICP selector", tenantId, (b2ScoringApi+"/scoredefinition/"+tenantId));

  const getLocalIcp = () => {
   // let icp = localStorage.getItem(`'icp'+${tenantId}+${userUuid}`);
   let icp=getLastUsedIcp()
    return icp;
  }

  

  const getLastUsedIcp=(json)=>{
    console.log("I have been called")
    const icpListTemp=json.map((icp)=>{
      return icp.id.icpName;
  })
    if(props.globalIcp && icpListTemp.includes(props.globalIcp)){
        // props.setGlobalIcp(props.globalIcp)
        props.setIcpList(json)
        return
    }
    if(props.icpFromUrl && icpListTemp.includes(props.icpFromUrl )){
      props.setGlobalIcp(props.icpFromUrl)
      props.setIcpList(json)
      return
  }
    fetch(`${window._env_.AUTH_URL}/users/get-last-used-icp`, {

        method: "GET",
        credentials: "include",
      })
        .then((res) => res.json())
        .then((icpData) => { 
            console.log("last icp response",icpData,props?.icpList);
            
            
            
            if(icpData.success &&icpData?.data && icpData?.data!==null && icpData?.data?.length>0){
                console.log("list",icpListTemp,icpData.data);
                if(icpListTemp.includes(icpData.data)){
                props.setGlobalIcp(icpData.data)
                }else{
                    console.log("else")
                    props.setGlobalIcp(json[0].id.icpName)
                }
             }else{
                console.log("else")
                props.setGlobalIcp(json[0].id.icpName)
            }
            props.setIcpList(json)
            //return null;
        //   if (loginData.success) {
        //     const time=new Date((typeof loginData.data === "string" ? new Date(loginData.data) : loginData.data).toLocaleString("en-US", {timeZone: timeZone})); 
        //     console.log("time",time.toString())
        //     setLastLoginTime(time.toString())
        //   }
        //   else{
        //     console.log(loginData.error);
        //   }
        });
  }
  
  const getIcpList = () => {
    //console.log("lasticp",lastIcp);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' },
      credentials: "include"
    };
    //getLastUsedIcp();
    //setLastUsedIcp(icpValue);
    fetch(`${b2ScoringApi}/scoredefinition/withoutFilters/${tenantId}`, requestOptions)
      .then(res => res.json())
      .then(json => {
        if (Array.isArray(json) && json.length > 0){
          // setIcpName(json[0].id.icpName)
          //if(!props.globalIcp) {
            //const value=lastUsedIcp;
            getLastUsedIcp(json);
            //console.log("user management ui response of icpselected", json[0].id.icpName);
            // const icpListTemp=json.map((icp)=>{
            //     return icp.id.icpName;
            // })
            // console.log("lasticp outside",lastIcp);
            // console.log("list",icpListTemp,lastIcp);
            // if(icpListTemp.includes(lastIcp)){
            //     console.log("if",lastIcp)
            //     props.setGlobalIcp(lastIcp)
            // }else{
            //     console.log("else")
            //     props.setGlobalIcp(json[0].id.icpName)
            // }
            
            //props.setGlobalIcp(lastUsedIcp || json[0].id.icpName)
          }
          
        //}
      })
      .catch(err => {
        console.error("user management ui icpselector error",err);
      })
      
      
  }

//   React.useEffect(() => {
//     getLastUsedIcp();
//     // if(icp){
//     //     setLastUsedIcp(icp);
//     // }
//   }, [])
  

  React.useEffect(() => {
    if(prev_token !== localStorage.getItem('b2bToken')) {
      props.setIcpList([])
      props.setGlobalIcp('')

    }
    //will cause a issue here
    if(localStorage.getItem('b2bToken') && (!props.globalIcp || prev_token !== localStorage.getItem('b2bToken'))){
      console.log("user management ui useffect if condition satisified")
      prev_token = localStorage.getItem('b2bToken');
      
    //   const lastIcp=getLastUsedIcp();
    //   console.log("lasticp outside",lastIcp);
      getIcpList();
    }

    //props.setGlobalIcp
    
  }, [localStorage.getItem('b2bToken')])

  React.useEffect(() => {
    if(props.icpFromUrl && localStorage.getItem('b2bToken') && props.icpFromUrl !== props.globalIcp && props.globalIcp == ''){
      getIcpList();
    }
  }, [props.icpFromUrl, props.globalIcp])

   const classes = useStyles();

  const handleChange = (event) => {
    // setIcpName(event.target.value);
    console.log("changed")
    props.setGlobalIcp(event.target.value)
    updateIcp(event.target.value);
    //needs to change it
    localStorage.setItem(`'icp'+${tenantId}+${userUuid}`,event.target.value);
  };
const updateIcp=(icp)=>{
    try {
        console.log("yess",icp)
        fetch(`${window._env_.AUTH_URL}/users/update-icp`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
              },
            body:JSON.stringify({icp:icp})
          })
            .then((res) => res.json())
            .then((icpData) => { 
                // if(icpData){
                //     setGlobalIcp(icpData);
                // }
            //   if (loginData.success) {
            //     const time=new Date((typeof loginData.data === "string" ? new Date(loginData.data) : loginData.data).toLocaleString("en-US", {timeZone: timeZone})); 
            //     console.log("time",time.toString())
            //     setLastLoginTime(time.toString())
            //   }
            //   else{
            //     console.log(loginData.error);
            //   }
            });
    } catch (error) {
        console.log("Error happened while updating icp");
    }
}  
const handleClose= ()=>{
  props.MouseLeave()
  setOpen(!open)
}
  return (
    <Box id="icp_selector_box" style={{ minWidth: "100%", borderRadius: "10px", border: "none" }} onClick={() => handleClose()} onMouseLeave={() => setOpen(false)}>
      <FormControl fullWidth>
        {Array.isArray(props?.icpList) && props?.icpList?.length > 0 ? (
          <Select
            id="icp-select"
            value={props.globalIcp}
            open={open}
            onMouseLeave={() => setOpen(false)}
            onChange={handleChange}
            variant="standard"
            disableUnderline
            size="small"
            sx={
              props?.icpList.filter((icp) => icp?.id?.icpName === props?.globalIcp)?.[0]?.isPublished
                ? {
                    "& .MuiSelect-standard": {
                      backgroundColor: "white",
                    },
                    ".MuiSelect-iconStandard": {
                      color: "#3D3D3B",
                      paddingRight: "12px",
                    },
                    borderRadius: "8px",
                  }
                : {
                    "& .MuiSelect-standard": {
                      backgroundColor: "#FFB026",
                    },
                    ".MuiSelect-iconStandard": {
                      paddingRight: "12px",
                    },
                    borderRadius: "8px",
                  }
            }
            // anchorEl={anchor}
            onClose={() => setOpen(false)}
            className={classes.select}
            IconComponent={ExpandMoreOutlinedIcon}
            inputProps={{
              classes: {
                icon: classes.iconInDD,
                root: classes.rootOnDD,
              },
            }}
            MenuProps={{
              PaperProps: {
                className: classes["icp-popper-menu"],
              },

              getContentAnchorEl: () => null,
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
              transformOrigin: { vertical: "top", horizontal: "center" },
            }}
            renderValue={(value) => {
              return (
                <>
                  <div className={classes["selected-body"]}>
                    {props?.icpList.filter((icp) => icp?.id?.icpName === props?.globalIcp)?.[0]?.isPublished ? <IcpAvatar name={value?.substring(0, 2)} fullName={value} /> : <WarningAmberIcon size="24px" />}
                    <Typography style={{ fontSize: "14px", fontWeight: "500", color: "#3D3D3B", overflow: "hidden", textOverflow: "ellipsis" }}>
                      {value}
                    </Typography>
                  </div>
                </>
              );
            }}
          >
            {props?.icpList.map((icp) => (
              <MenuItem style={{ color: "#8A8A8A" }} onClick={() => setOpen(false)} value={icp.id.icpName} classes={{ selected: classes.selected }}>
                <IcpAvatar size="20px" fontSize="7.5px" name={icp?.id?.icpName?.substring(0, 2)} fullName={icp?.id?.icpName} />
                <Typography style={{ fontSize: "14px", fontWeight: "500", color: "#3D3D3B", overflow: "hidden", textOverflow: "ellipsis" }}>
                  {icp?.id?.icpName || ""}
                </Typography>
                {!icp?.isPublished ? (
                  <Tooltip title="Profile is not published" placement="right">
                    <WarningAmberIcon />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </MenuItem>
            ))}
          </Select>
        ) : (
          <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#323232", fontSize: "12px", fontWeight: "500", height: "40px" }}>Loading..</div>
          </>
        )}
      </FormControl>
    </Box>
  );
}

export default IcpSelector;
