import "./fallbackcomponenets.css";
import CircularProgress from "@mui/material/CircularProgress";
import { NoData } from "../../media/svgIcons";
import { styled } from '@mui/system';


export const CustomLoader = () => {

  return (
    <svg style={{ animation: "spin 2s linear infinite" }} width="13" height="13" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" stroke="#333" strokeWidth="4" strokeOpacity="0.6"></circle>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0V4C7.58172 4 4 7.58172 4 12C4 12.3387 4.02104 12.6724 4.06189 13H0.0410728C0.0138702 12.6703 0 12.3368 0 12Z" fill="#333"></path>
    </svg>
  

  );

};

export const Loader = () => {
  return (
    <div className="circular-progress-div">
      <CircularProgress style={{ color: "#016B40", alignSelf:"center" }} />
    </div>
  );
};

export const MainPageLoader = () => {
  return (
    <div className="circular-progress-main-page-div">
      <CircularProgress style={{ color: "#016B40" }} />
    </div>
  );
};

export const SmallLoader = () => {
  return (
    <div className="circular-progress-div-small">
      <CircularProgress
        style={{
          color: "#016B40",
          width: "30px",
          height: "30px",
          opacity: "100%",
        }}
      />
    </div>
  );
};

export const NoResultFound = () => {
  return (
    <div className="no-data-svg-div">
      <NoData />
      <span className="no-data-text">No data found</span>
    </div>
  );
};

export const MainPageNoResultFound = () => {
  return (
    <div className="no-data-main-page-svg-div">
      <NoData />
      <span className="no-data-text">No data found</span>
    </div>
  );
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
  };
};