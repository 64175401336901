export const LogoutIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H11C11.2833 3 11.5208 3.09583 11.7125 3.2875C11.9042 3.47917 12 3.71667 12 4C12 4.28333 11.9042 4.52083 11.7125 4.7125C11.5208 4.90417 11.2833 5 11 5H5V19H11C11.2833 19 11.5208 19.0958 11.7125 19.2875C11.9042 19.4792 12 19.7167 12 20C12 20.2833 11.9042 20.5208 11.7125 20.7125C11.5208 20.9042 11.2833 21 11 21H5ZM17.175 13H10C9.71667 13 9.47917 12.9042 9.2875 12.7125C9.09583 12.5208 9 12.2833 9 12C9 11.7167 9.09583 11.4792 9.2875 11.2875C9.47917 11.0958 9.71667 11 10 11H17.175L15.3 9.125C15.1167 8.94167 15.025 8.71667 15.025 8.45C15.025 8.18333 15.1167 7.95 15.3 7.75C15.4833 7.55 15.7167 7.44583 16 7.4375C16.2833 7.42917 16.525 7.525 16.725 7.725L20.3 11.3C20.5 11.5 20.6 11.7333 20.6 12C20.6 12.2667 20.5 12.5 20.3 12.7L16.725 16.275C16.525 16.475 16.2875 16.5708 16.0125 16.5625C15.7375 16.5542 15.5 16.45 15.3 16.25C15.1167 16.05 15.0292 15.8125 15.0375 15.5375C15.0458 15.2625 15.1417 15.0333 15.325 14.85L17.175 13Z"
        fill="#3D3D3B"
      />
    </svg>
  );
};

export const NoData = () => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="66" height="66" rx="10" fill="#F5F5F5" />
    <path
      d="M17 48V46H39V48H17ZM41.0057 34C38.5173 34 36.3942 33.1231 34.6366 31.3692C32.8789 29.6153 32 27.4941 32 25.0057C32 22.5173 32.877 20.3942 34.6308 18.6366C36.3847 16.8789 38.5059 16 40.9943 16C43.4827 16 45.6058 16.877 47.3634 18.6309C49.1212 20.3847 50 22.5059 50 24.9943C50 27.4827 49.1231 29.6058 47.3692 31.3634C45.6153 33.1211 43.4941 34 41.0057 34ZM17 32V30H27.7538C27.8846 30.3744 28.0295 30.7218 28.1885 31.0423C28.3475 31.3628 28.5282 31.6821 28.7308 32H17ZM17 40V38H35.5308C36.0667 38.2359 36.6269 38.4417 37.2116 38.6173C37.7962 38.7929 38.3923 38.9218 39 39.0039V40H17ZM40.2307 30.7693H41.7693V23.6154H40.2307V30.7693ZM41 21.3846C41.2667 21.3846 41.5 21.2846 41.7 21.0846C41.9 20.8846 42 20.6513 42 20.3846C42 20.1179 41.9 19.8846 41.7 19.6846C41.5 19.4846 41.2667 19.3846 41 19.3846C40.7333 19.3846 40.5 19.4846 40.3 19.6846C40.1 19.8846 40 20.1179 40 20.3846C40 20.6513 40.1 20.8846 40.3 21.0846C40.5 21.2846 40.7333 21.3846 41 21.3846Z"
      fill="#3D3D3B"
    />
  </svg>
);

export const RefreshIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00008 13.3332C6.51119 13.3332 5.25008 12.8165 4.21675 11.7832C3.18341 10.7498 2.66675 9.48873 2.66675 7.99984C2.66675 6.51095 3.18341 5.24984 4.21675 4.2165C5.25008 3.18317 6.51119 2.6665 8.00008 2.6665C8.76675 2.6665 9.50008 2.82484 10.2001 3.1415C10.9001 3.45817 11.5001 3.91095 12.0001 4.49984V3.33317C12.0001 3.14428 12.064 2.98595 12.1917 2.85817C12.3195 2.73039 12.4779 2.6665 12.6667 2.6665C12.8556 2.6665 13.014 2.73039 13.1417 2.85817C13.2695 2.98595 13.3334 3.14428 13.3334 3.33317V6.6665C13.3334 6.85539 13.2695 7.01373 13.1417 7.1415C13.014 7.26928 12.8556 7.33317 12.6667 7.33317H9.33341C9.14453 7.33317 8.98619 7.26928 8.85841 7.1415C8.73064 7.01373 8.66675 6.85539 8.66675 6.6665C8.66675 6.47761 8.73064 6.31928 8.85841 6.1915C8.98619 6.06373 9.14453 5.99984 9.33341 5.99984H11.4667C11.1112 5.37761 10.6251 4.88873 10.0084 4.53317C9.39175 4.17761 8.7223 3.99984 8.00008 3.99984C6.88897 3.99984 5.94453 4.38873 5.16675 5.1665C4.38897 5.94428 4.00008 6.88873 4.00008 7.99984C4.00008 9.11095 4.38897 10.0554 5.16675 10.8332C5.94453 11.6109 6.88897 11.9998 8.00008 11.9998C8.75564 11.9998 9.4473 11.8082 10.0751 11.4248C10.7029 11.0415 11.189 10.5276 11.5334 9.88317C11.6223 9.72761 11.7473 9.61928 11.9084 9.55817C12.0695 9.49706 12.2334 9.49428 12.4001 9.54984C12.5779 9.60539 12.7056 9.72206 12.7834 9.89984C12.8612 10.0776 12.8556 10.2443 12.7667 10.3998C12.3112 11.2887 11.6612 11.9998 10.8167 12.5332C9.9723 13.0665 9.03341 13.3332 8.00008 13.3332Z" fill="#3D3D3B"/>
    </svg>

  );
}

export const DeleteIcon = () => {
  
  return (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M2.83398 15.5C2.37565 15.5 1.98329 15.3368 1.6569 15.0104C1.33051 14.684 1.16732 14.2917 1.16732 13.8333V3H0.333984V1.33333H4.50065V0.5H9.50065V1.33333H13.6673V3H12.834V13.8333C12.834 14.2917 12.6708 14.684 12.3444 15.0104C12.018 15.3368 11.6257 15.5 11.1673 15.5H2.83398ZM11.1673 3H2.83398V13.8333H11.1673V3ZM4.50065 12.1667H6.16732V4.66667H4.50065V12.1667ZM7.83398 12.1667H9.50065V4.66667H7.83398V12.1667Z" fill="#5F6368"/>
  </svg>

  )
}

export const InformationIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.37565 13.9584H10.6257V9.16671H9.37565V13.9584ZM10.0007 7.74046C10.1913 7.74046 10.3512 7.67594 10.4802 7.54692C10.6093 7.41789 10.6738 7.25803 10.6738 7.06733C10.6738 6.87664 10.6093 6.71678 10.4802 6.58775C10.3512 6.45886 10.1913 6.39442 10.0007 6.39442C9.80996 6.39442 9.6501 6.45886 9.52107 6.58775C9.39204 6.71678 9.32753 6.87664 9.32753 7.06733C9.32753 7.25803 9.39204 7.41789 9.52107 7.54692C9.6501 7.67594 9.80996 7.74046 10.0007 7.74046ZM10.0021 17.9167C8.90711 17.9167 7.87787 17.7089 6.9144 17.2934C5.95093 16.8778 5.11287 16.3139 4.40023 15.6015C3.6876 14.8891 3.12336 14.0514 2.70753 13.0884C2.29183 12.1253 2.08398 11.0964 2.08398 10.0015C2.08398 8.9065 2.29176 7.87726 2.70732 6.91379C3.12287 5.95032 3.68683 5.11226 4.39919 4.39962C5.11155 3.68698 5.94926 3.12275 6.91232 2.70692C7.87537 2.29122 8.90433 2.08337 9.99919 2.08337C11.0942 2.08337 12.1234 2.29115 13.0869 2.70671C14.0504 3.12226 14.8884 3.68622 15.6011 4.39858C16.3137 5.11094 16.8779 5.94865 17.2938 6.91171C17.7095 7.87476 17.9173 8.90372 17.9173 9.99858C17.9173 11.0936 17.7095 12.1228 17.294 13.0863C16.8784 14.0498 16.3145 14.8878 15.6021 15.6005C14.8897 16.3131 14.052 16.8773 13.089 17.2932C12.1259 17.7089 11.097 17.9167 10.0021 17.9167ZM10.0007 16.6667C11.8618 16.6667 13.4382 16.0209 14.7298 14.7292C16.0215 13.4375 16.6673 11.8612 16.6673 10C16.6673 8.13893 16.0215 6.56254 14.7298 5.27087C13.4382 3.97921 11.8618 3.33337 10.0007 3.33337C8.13954 3.33337 6.56315 3.97921 5.27148 5.27087C3.97982 6.56254 3.33398 8.13893 3.33398 10C3.33398 11.8612 3.97982 13.4375 5.27148 14.7292C6.56315 16.0209 8.13954 16.6667 10.0007 16.6667Z" fill="#888888"/>
    </svg>
  )
}