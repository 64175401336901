import React, { useState } from "react";
import ReactDOM from "react-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useAuthState } from "@bamboobox/b2logincheck";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// import Search from "@material-ui/icons/Search";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from "react-toastify";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
// import "./gridindex.css";

import ReactFC from "react-fusioncharts";
import { pink } from "@material-ui/core/colors";
import { CircularProgress, InputAdornment, Popper } from "@material-ui/core";
// import handleRenderOption from "./highlight";
import "./toast.css"
import "./contactGrid.css";
import Alert from '@mui/material/Alert';
import jwt_decode from "jwt-decode";


toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  // toastClassName: css({
  //   fontSize: "18px !important",
  //   backgroundColor: "#da1c36 !important",
  //   padding: "10px !important",
  //   position: "absolute",
  //   bottom: "120px",
  // }),
});

var typeAheadUrl0 = window._env_.UNIFIED_URL + "/search/contact?tenantId=";
var typeAheadUrl1 = "&query=";

const PopperMy = function (props) {
  const styles = (theme) => ({
    popper: {
       width: "400px",
    }
 });
 console.log('props in popper',props);
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

const ComposeMail = (props) => {
  console.log("contactName", props.contactName)
  const [myCompany, setMyCompany] = useState([]);
  let userData = {};
  let tenantId = '';
  if(localStorage.b2bToken){
    try {
    
    userData = jwt_decode(localStorage.b2bToken || "");
    tenantId = userData?.tenantId;
      
    } catch (error) {
      console.log(error);
    }
      
  }
  const [highligth, setHighligth] = useState();
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  var listAllData = [];
  console.log("highligth", highligth);
  const ContactSelected = (e, option) => {
    if (option.accountId === undefined && option.length <= 2) {
      toast.error(<Alert severity="error">Enter at least 3 characters</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    } else if (option.accountId === undefined && option.length >= 3) {
      toast.error(<Alert severity="error">Search Contact is not present in the list</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true,
      });
    } else {
      props.setPersonalisedContObj(prev => ({"accountId":option.accountId,"contactId":option.contactId}));
    }
  };

  const getContactDataFromAPI = (e, v) => {
    setSearchInput(e.target.value);
    e.preventDefault();
    props.setPersonalisedContObj();
    if (e.target.value.length > 1) {
      setLoading(true);
      setHighligth(e.target.value);

      fetch(
        typeAheadUrl0 + userData.tenantId + typeAheadUrl1 + e.target.value,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then((data) => {
          listAllData = data;
          var tmpData = [];

          for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < data[i].length; j++) tmpData.push(data[i][j]);
          }

          tmpData.sort((a, b) => {
            return a.email.localeCompare(b.email);
          });
          setLoading(false);
          setMyCompany(tmpData);

          // console.log("mycompany",myCompany[0].nameFlag);
        });
    } else {
      setLoading(false);
      setMyCompany([]);

      // console.log("mycompany",myCompany[nameFlag]);
    }
    // console.log("mycompany",myCompany[nameFlag]);
  };
  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option, v, e) =>
      // option.nameFlag == true ? option.contactName : option.email,
      {
        console.log("search", option, v, e);
        if (option.nameFlag == true || props?.detailPage) {
          // let arr = option.contactName.split(" ");

          // for (let i = 0; i < arr.lenght; arr++) {
          if (
            (option.firstName || "").toLowerCase().includes((searchInput || "").toLowerCase())
          ) {
            return option.firstName;
          } else if (
            (option.lastName || "").toLowerCase().includes((searchInput || "").toLowerCase())
          ) {
            return option.lastName;
          } else {
            return option.contactName;
          }

          // }
        } else {
          return option.email;
        }
      },
  });

  const handleRenderOption = (option, { inputValue }) => {
    console.log("inside handleRender");
    const matches = match(option.contactName, inputValue);
    const parts = parse(option.contactName, matches);
    const matchesEmail = match(option.email, inputValue);
    const partsEmail = parse(option.email, matchesEmail);
  
    const highlightStyle = {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "bold",
    };
    const nonHighlightStyle = {
      position: "static",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "14px",
      lineHeight: "143%",
    };
  
    return (
      <div >
        {(option.nameFlag == true || props.detailPage)
          ?
          parts.map((part, index) => (
              <span
                key={index}
                style={part.highlight ? highlightStyle : nonHighlightStyle}
              >
                {part.text}
              </span>
            ))
  
          : 
          partsEmail.map((part, index) => (
              <span
                key={index}
                style={part.highlight ? highlightStyle : nonHighlightStyle}
              >
                {part.text}
              </span>
            ))}
        {option.nameFlag == true || props.detailPage ? (
          <p2
            style={{
              position: "static",
              color: "rgba(0, 0, 0, 0.54)",
              paddingLeft: "8px",
              fontSize: "12px",
              lineHeight: "166%",
              lineSpacing: "0.4px",
              paddingLeft: "8px",
            }}
          >{`  ${option.companyName ?? ''} ${option.email ?? ''}` }</p2>
        ) : (
          <p2
            style={{
              position: "static",
              color: "rgba(0, 0, 0, 0.54)",
              paddingLeft: "8px",
              fontSize: "12px",
              lineHeight: "166%",
              lineSpacing: "0.4px",
              paddingLeft: "8px",
            }}
          >{`  ${option.contactName ?? ''} , ${option.companyName ?? option.company ?? ''}`}</p2>
        )}
      </div>
    );
  };

  return (
    <div id="custautoper" style={{ width: "220px"}}>
      <Autocomplete
        className="cusAutoComp"
        PopperComponent={PopperMy}
        id="searchbox"
        style={{ padding: 0, width: '220px' }}
        freeSolo
        autoComplete
        autoHighlight
        onChange={ContactSelected}
        options={props?.detailPage ? props?.listOfContacts || []: myCompany}
        loading={loading}
        filterOptions={filterOptions}
        getOptionLabel={(option, value) => {
          console.log(value);
          if (option.nameFlag == true || props.detailPage) {
            return option.contactName;
          } else {
            return option.email;
          }
        }}
  

        renderInput={(params) => (
          <TextField
            {...params}
            onChange={getContactDataFromAPI}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{color:"#BEBEBE"}}/>
                </InputAdornment>         
              ),
              placeholder: props.contactName || 'search for contacts',
            style:{
              fontSize: "16px",
              lineHeight: "14px",
              color: "#030303",
              borderRadius: "4px",
              height:"32px",
              boxSizing:"border-box",
              padding:"0px 8px 6px 6px",
              width: '220px'
            },
            }}
          />
        )}
        renderOption={handleRenderOption}
      />
    </div>
  );
};
{
  /*  */
}
export default ComposeMail;



