import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function SalesforceCallback() {

    const history = useHistory();
    const tenantId = localStorage.getItem('b2bTenantId');
    
    useEffect(() => {
        const code = getParameterByName('code');
        console.log("Code: "+code);
        console.log("tenantId--->",tenantId);
        console.log("window._env_.SALESFORCE_BULK_SYNC --->",window._env_.SALESFORCE_BULK_SYNC);
        console.log("b2bToken from cache--->",localStorage.getItem('b2bToken'));
        if (code) {
            fetch(`${window._env_.SALESFORCE_BULK_SYNC}/salesforce/auth/callback?code=${code}&tenantId=${tenantId}`, {
                method: 'GET',
                credentials : 'include',
                headers: {
                    "auth-token" : `${localStorage.getItem('b2bToken')}`
                }
            }).then(res => {
                history.push('/salesforce-sync');
            })
        }
    });

    return (
        <p>Salesforce Autenticated</p>
    )
}

export default SalesforceCallback;
// lt:3000
// developer console
// change local storage key in line 14