import { useState, useEffect,useRef, Fragment } from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import shortid from "shortid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { CATEGORIES } from "../page2/constants";
import { useHistory, useLocation } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
import IcpSelector from "./IcpSelector";
import Divider from '@mui/material/Divider';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  IconButton,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import Alert from '@mui/material/Alert';
import bblogo from "./bblogo.jpg";
import BambooboxLogoMark from "./BambooboxLogoMark.svg";
import BambooboxFullLogoMark from "./BambooboxFullLogoMark.svg";
import "./styles.css";
import UITheme from "@bamboobox/b2-theme";
import LoginIcon from "@material-ui/icons/PowerSettingsNew";
import { LogoutIcon } from "./../../media/svgIcons";
import Logout from "@mui/icons-material/Logout";
import { Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import "./toast.css";
import Badge from '@mui/material/Badge';
import PersonalizedEmail from "./PersonalizedEmail";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import jwt_decode from "jwt-decode";
import ErrorIcon from '@mui/icons-material/Error';
import { IcpAvatar } from "./icpAvatar";
import { User, UserAvatar } from "./userMenu/user";
import jwtDecode from "jwt-decode";

// import {  useLocation } from "react-router-dom";
const mapHash = {
  "account-dashboard": 0,
  "home": 0,
  "account-contact-creation": 1,
  "account-list-view": 1,
  "web-form-fill": 1,
  "enrichment-queue": 1,
  "unified-account-profile": 2,
  "export-manager": 2,
  "campaign-manager": 2,
  "campaign-hub": 3,
  "attribute-setup": 4,
  "integration-setup": 4,
  "webhooks-setup": 4,
  "zoho-setup": 4,
  "salesforce-setup": 4,
  "icp-score-configuration": 4,
  // "trend-report":4,
  // "account-management-summary":4,
  "admin-settings": 5,
  "manual_data_upload": 7,
};



function Header(props) {
  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });

     /*global chrome*/
//   const [lastLoginTime,setLastLoginTime]=useState('');
  let permissions = [];
  let userDetails = {};
  let tenantId = '';
  if(localStorage.b2bToken){
    try {
    
    userDetails = jwt_decode(localStorage.b2bToken || "");
    tenantId = userDetails?.tenantId;
      
    } catch (error) {
      console.error("error",error)
    }
  }


  const location = useLocation();
  console.log("location", location)
  const [selectIndex, setSelectIndex] = useState(mapHash[location.pathname.replace('/', '')]);
  const [personalisedEmailDialogOpen,setPersonalisedEmailDialogOpen] = useState(false);
  const [personalisedContObj,setPersonalisedContObj] = useState();
  const [options,setOptions]=useState(props.menuOptions);
  const [fetchNotifData,setFetchNotifData]=useState(false);
  
  useEffect(() => {
    setSelectIndex(mapHash[location.pathname.replace('/', '')])
  }, [location.pathname])

  const getNotifications=()=>{
    fetch(`${window._env_.AUTH_URL}/notifications/get-notifications`, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        console.log("api response",json);
        if (json.success) {
          setFetchNotifData(true)
          let resourceNames=new Map();
          if(json?.data?.length>0)
          json?.data.map((obj)=>{
            if(obj.resourceName && obj.resolved===false){
              resourceNames.set(obj.resourceName.toUpperCase(),obj);
            }
          })
          const newOptions=CATEGORIES.map((obj)=>{
            if(resourceNames.has(obj.text.toUpperCase())){
              const value=resourceNames.get(obj.text.toUpperCase());
              obj.warning=value?.severity;
              if(obj.subCategories.length>0 && value?.subResourceName!==null && (typeof value?.subResourceName==='string')){
                obj.subCategories.map((sub)=>{
                  if(sub.label.toUpperCase() === value.subResourceName.toUpperCase()){
                    sub.warning=value?.severity;
                  }
                })
              }
            }
            return obj;
          })
          console.log("newoptions",newOptions);
          setOptions(newOptions);
        }
      }).catch((error)=>{
        console.log("notification error",error);
      });
  }

  // useEffect(()=>{
  //   console.log("is auth triggered",props.isAuthenticated);
  //   if(!fetchNotifData){
  //     getNotifications()
  //   }
  // },[props.isAuthenticated])

//   useEffect(()=>{
//     getLastLoginDate();
//   },[])

  permissions = JSON.parse(localStorage.getItem("b2Permissions"));

  

 

  function isPermitted(screen) {
    if ((!permissions) || (permissions.length === 0)) return false;
    var isAllowed = false;
    permissions.map((item) => { if (item.resourceName.toLowerCase() === screen?.toLowerCase()) isAllowed = true; });
    return isAllowed;
  }
  function isPermittedCategory(category) {
    if (category.key === "RECENTS") return true;
    if (category.subCategories.filter((subcategory) => { return isPermitted(subcategory.value) }).length > 0)
      return true;
    if(category?.subResources?.filter((subResource)=>isPermitted(subResource)).length>0) return true  
    else return false;
  }
  const [recentRoutes, setRecentRoutes] = useState(
    localStorage.getItem("routes")
      ? JSON.parse(localStorage.getItem("routes"))
      : []
  );

  const history = useHistory();
  function handleRoutesClick(subCategory, index) {
    setSubIndex(index)
    console.log("handleRoutesClick subCategory", subCategory)
    const routes = localStorage.getItem("routes");
    let routesArr = [];
    let routeIndex = 0;
    if((props?.icpList?.filter(icp => icp.id.icpName === props.globalIcp)?.[0]?.isPublished)) {
      if(subCategory.value !== 'email_generator'){
        if (routes) {
          routeIndex = JSON.parse(routes).indexOf(subCategory.route);
          console.log("index routes", routeIndex)
          routesArr = [...JSON.parse(routes)];
          console.log("handleRoutesClick routeIndexArr", routeIndex, routesArr)
          let removedRoute = [];
          if (routeIndex !== -1) {
            removedRoute = routesArr.splice(routeIndex, 1);
            routesArr.push(subCategory.route);
            console.log("handleRoutesClick -1", removedRoute, routesArr)
          } else {
            console.log("handleRoutesClick else")
            if (routesArr.length < 3) {
              routesArr.push(subCategory.route);
              console.log("handleRoutesClick else <3", routesArr, subCategory.route)
            } else {
              removedRoute = routesArr.splice(0, 1);
              routesArr.push(subCategory.route);
              console.log("handleRoutesClick else >=3", routesArr, removedRoute, subCategory.route)
            }
          }
          if (removedRoute.length && routeIndex === -1) {
            console.log("handleRoutesClick removeitem", removedRoute[0])
            localStorage.removeItem(removedRoute[0]);
          }
        } else {
          routesArr = [subCategory.route];
          console.log("handleRoutesClick routes not defined", routesArr)
        }
    
        setRecentRoutes(routesArr);
        localStorage.setItem("routes", JSON.stringify(routesArr));
        localStorage.setItem(subCategory.route, JSON.stringify(subCategory));
    
    
    
        if ((location.pathname || '').replace('/', '') === subCategory.route) {
          window.location.reload()
          console.log("handleRoutesClick reload", subCategory.route)
        } else {
          localStorage.setItem("clicked", subCategory.route);
          console.log("handleRoutesClick route - /", subCategory.route, location.pathname)
          history.push(`/${subCategory.route}`);
        }
      }else{
        setPersonalisedEmailDialogOpen(true);
      }
    }else{
      toast.warn(<Alert severity="warning">Redirecting to configuration screen as profile is not published</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
      if(isPermitted("icp_score_configuration")){
        localStorage.setItem("clicked", 'icp-score-configuration');
        history.push(`/icp-score-configuration`);
      }else{
        localStorage.setItem("clicked", 'no-access-page');
        history.push(`/no-access-page`);
      }
    }
  }

  function renderRecentRoutes(index) {
    return [...recentRoutes].reverse().map((route) => {
      const subCat = JSON.parse(localStorage.getItem(route));
      if (subCat)
        return (
          <div className="menuListText2" onClick={() => handleRoutesClick(subCat)}>
            {subCat.label}
          </div>
        );
    });
  }

  function directRouteClick(category, index) {
    console.log("index of recents", index, menuIndex, category)
    index === menuIndex ? setMenuIndex(-1) : setMenuIndex(index)
    
    if (category.text.toUpperCase() === 'CAMPAIGN HUB') {
      handleRoutesClick(category.subCategories[0])
    }
    if (category.text.toUpperCase() === 'ADMIN SETTINGS') {
      handleRoutesClick(category.subCategories[0])
    }
    if (category.text.toUpperCase() === 'DASHBOARD') {
      handleRoutesClick(category.subCategories[0])
    }
    if (category.text.toUpperCase() === 'DATA UPLOAD') {
      handleRoutesClick(category.subCategories[0])
    }
  }


  const [hideIcp, setHideIcp] = useState("none")
  const [menuWidth, setMenuWidth] = useState("70px")
  const [menuTop, setMenuTop] = useState("0vh")
  const [menuHeight, setMenuHeight] = useState("100vh")
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  

  function logOut() {
    //sessionStorage.setItem('loggedIn','false');
    fetch(`${window._env_.AUTH_URL}/users/logout`, {
      method: "POST",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.success) {
          localStorage.clear();
          sessionStorage.clear();
          history.push("/");
        }
      });
    localStorage.removeItem("b2bToken");
    localStorage.removeItem('b2LoggedIn');
    localStorage.removeItem("b2bPermission");
    localStorage.removeItem("tenantImage");
    localStorage.removeItem("b2iat");
    localStorage.removeItem("b2exp");
    localStorage.removeItem("lastActivityTime");
    //sessionStorage.clear();
    //setLastLoginTime("");
    console.log("logged out")
  }
  function MouseOver(event) {
    setHideIcp("flex")
    setFlag(1)
    setMenuWidth("270px")
    setMenuTop("1.5vh")
    setMenuHeight("100vh")
  }
  function MouseLeave(event) {
    setHideIcp("none")
    setFlag(0)
    setMenuWidth("70px")
    setMenuTop("0vh")
    setMenuHeight("100vh")
    setMenuIndex(selectIndex)
  }
//   const getLastLoginDate=async()=>{
//     const timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
//     console.log(timeZone);
//     fetch(`${window._env_.AUTH_URL}/users/get-last-login`, {
//         method: "GET",
//         credentials: "include",
//       })
//         .then((res) => res.json())
//         .then((loginData) => { 
//             console.log("response",loginData);
//           if (loginData.success) {
//             const time=new Date((typeof loginData.data === "string" ? new Date(loginData.data) : loginData.data).toLocaleString("en-US", {timeZone: timeZone})); 
//             console.log("time",time.toString())
//             setLastLoginTime(time.toString())
//           }
//           else{
//             console.log(loginData.error);
//           }
//         });
//   }
  function handleClickAway() {
    // setMenuIndex(-1)
  }
  const [flag, setFlag] = useState(0);
  const [menuIndex, setMenuIndex] = useState(-1)
  const [subIndex, setSubIndex] = useState(-1)
  const [refreshed,setRefreshed]=useState(true);
  const [tenantImage,setTenantImage]=useState("");

//     if(lastLoginTime===''){
//     getLastLoginDate();
//   }

 // Used for confirmation, to closing the window 
//  window.addEventListener('beforeunload', (event) => {
    
//   });

useEffect(() => {
  let icpSelected = props?.icpList?.filter(icp=>icp?.id?.icpName===props?.globalIcp);
  const imageUrl=localStorage.getItem("tenantImage");
    if(imageUrl){
      setTenantImage(imageUrl);
    }
  if(props?.globalIcp && props?.icpList?.length>0 && !(icpSelected?.[0]?.isPublished === 'true' || icpSelected?.[0]?.isPublished === true)){
    toast.warn(<Alert severity="warning">Redirecting to configuration screen as profile is not published</Alert>,{
      closeOnClick: true,
      autoClose: true,
      closeButton: true,
      newestOnTop: true
    });
    if(isPermitted("icp_score_configuration")){
      localStorage.setItem("clicked", 'icp-score-configuration');
      history.push(`/icp-score-configuration`);
    }else{
      localStorage.setItem("clicked", 'no-access-page');
      history.push(`/no-access`);
    }  
  }
}, [props?.globalIcp, props?.icpList])

useEffect(() => {
  const token = localStorage.getItem("b2bToken");
  if(token){
    const userData = jwtDecode(token);
    setName(userData?.name);
    setEmail(userData?.email);
  }
}, [props?.lastLoginTime])



//Used to logout the session, when browser window was closed 
window.onunload =  function (event) {
    // if(window === window.parent){
    localStorage.removeItem('b2LoggedIn');   
    // } 
};

window.beforeunload = function(event) {
   if(window === window.parent) {
   localStorage.setItem('previousPath', window.location.href);
   }
   else {
    localStorage.setItem('parentPreviousPath', window.location.href);
   }
}
// window.addEventListener('beforeunload', function() {
//     chrome.runtime.sendMessage({ info: "Here is the info you would like to pass to background page"});
// });

// chrome.runtime.onMessage.addListener(function(request) {
//     // The following is your async call
//     logOut();
//     // Don't forget the following code
//     return true;
// });

// useEffect(()=>{
//     chrome.runtime.sendMessage({ info: "Here is the info you would like to pass to background page"});
// })
// sessionStorage.setItem('reloaded', 'yes');
// if (sessionStorage.getItem('reloaded') != null) {
//     console.log('page was reloaded');
    
// } else {
//     console.log('page was not reloaded');
//     logOut();
// }



// useEffect(()=>{
//     if (performance.navigation.type === 1) {
//         console.info( "This page is reloaded" );
//         setRefreshed(true);
//     } else {
//         console.info( "This page is not reloaded");
//         setRefreshed(false);
//     } 
// })

// window.onbeforeunload= async function(event){
//         if(false){
//             await fetch(`${window._env_.AUTH_URL}/users/logout`, {
//                 method: "POST",
//                 credentials: "include",
//                 })
//             event.preventDefault();
//             event.returnValue = false;
//                 }
//          // Seems require this for Chrome
//     };
    
// useEffect(()=>{
//     console.log("--------------------------------",sessionStorage.getItem('loggedIn'),sessionStorage.getItem('loggedIn')!=='true')
// if(sessionStorage.getItem('loggedIn')!=='true'){
//     debugger
//     logOut();
//     debugger
// }
// },[])


 
const date = new Date(props.lastLoginTime);
// const date=new Date("Tue Apr 18 2023 10:26:34 GMT+0530")
const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
const formattedTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false });
const timezone = 'IST';


  return (
    <>
      <ThemeProvider theme={UITheme}>
        <div id="header-container" className="header-container" onMouseOver={MouseOver} onMouseLeave={MouseLeave} style={flag === 1 ? { width: menuWidth } : { width: menuWidth }}>
          <div className="header-container-start" style={{ height: "calc(100vh - 130px)", overflow: "hidden" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "24px 0 0 10px", alignSelf: "flex-start" }}>
              {
                <a href="/#/home" style={{ height: "42px"}}>
                  {flag === 1 ? (
                    <>
                      <img
                        src={"undefinedjpg"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = BambooboxFullLogoMark;
                        }}
                        height="38"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={"dwsdsf.jpg"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = BambooboxLogoMark;
                        }}
                        height="38"
                      />
                    </>
                  )}
                </a>
              }
              {/* <div>
           {flag==1 && <div style={{ paddingLeft:"10px",alignItems: 'center',fontSize:"20px",color:"#9F9F6C"}}>BambooBox</div>}
           </div> */}
              {/* {flag==1 && <div style={{paddingLeft:"50px",alignItems: 'center',paddingTop:"5%",fill:"#3D3D3B"}}>
           <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line opacity="0.9" x1="17" y1="1" x2="1" y2="0.999999" stroke="#BEBEBE" stroke-width="2" stroke-linecap="round"/> 
            <line opacity="0.9" x1="17" y1="6" x2="9" y2="6" stroke="#BEBEBE" stroke-width="2" stroke-linecap="round"/>
            <line opacity="0.9" x1="17" y1="11" x2="9" y2="11" stroke="#BEBEBE" stroke-width="2" stroke-linecap="round"/>
            <line opacity="0.9" x1="17" y1="16" x2="1" y2="16" stroke="#BEBEBE" stroke-width="2" stroke-linecap="round"/>
            <path d="M0.5 8.5L5 5.0359L5 11.9641L0.5 8.5Z" fill="#C4C4C4"/>
            </svg>
           </div>} */}
            </div>

            <div style={{ display: hideIcp, width: "-webkit-fill-available", margin: "2em 10px", border: "1px solid #E0E2DE", borderRadius: "10px" }}>
              <IcpSelector icpFromUrl={props.icpFromUrl} icpList={props.icpList} setIcpList={props.setIcpList} setGlobalIcp={props.setGlobalIcp} globalIcp={props.globalIcp} MouseOver={MouseOver} MouseLeave={MouseLeave} />
            </div>

            <div className="categories-container" style={flag === 1 ? { overflowX: "hidden", overflowY: "scroll", width: "100%" } : {}}>
              {flag === 0 ? (
                <div
                  style={{ display: "flex", borderRadius: "10px", alignItems: "center", margin: "2em 0", padding: "8px 0 8px 8px", border: "1px solid #E0E2DE" }}
                >
                  <div>
                    <IcpAvatar name={props?.globalIcp?.substring(0, 2) || "__"} fullName={props?.globalIcp || "__"} />
                  </div>
                  <ExpandMoreIcon style={{ height: "16px", width: "16px" }} />
                </div>
              ) : (
                <></>
              )}

              <>
                {options.length > 0 &&
                  options.map((category, index) => {
                    if (!isPermittedCategory(category)) return <></>;

                    return (
                      <>
                        <div
                          style={
                            flag === 1
                              ? {
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  margin: "3px 10px",
                                  width: "-webkit-fill-available",
                                }
                              : {
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  margin: "3px 10px",
                                }
                          }
                          onClick={(event) => directRouteClick(category, index)}
                        >
                          <button
                            style={{
                              fontStyle: index === selectIndex ? "bold" : "normal",
                              backgroundColor: index === selectIndex || (menuIndex === index && flag == 1) ? "#F8F9F7" : "",
                            }}
                            className="categories-menu-button"
                          >
                            {index === selectIndex ? (
                              <div style={{ display: "flex", justifyContent: "flex-start", alignContent: "left", position: "relative" }}>
                                {category.selectIcon}
                                <div className={flag === 1 ? "open" : "close"}>
                                  {category.warning === "HIGH" && <WarningRoundedIcon stroke={"white"} stroke-width={1} style={{ width: "16px", color: "#FF9800" }} />}
                                  {category.warning === "VERY HIGH" && <ErrorIcon style={{ width: "16px", color: "#DD5B49" }} />}
                                </div>
                              </div>
                            ) : (
                              <div style={{ display: "flex", justifyContent: "flex-start", alignContent: "left", position: "relative" }}>
                                {category.icon}
                                <div className={flag === 1 ? "open" : "close"}>
                                  {category.warning === "HIGH" && <WarningRoundedIcon stroke={"white"} stroke-width={1} style={{ width: "16px", color: "#FF9800" }} />}
                                  {category.warning === "VERY HIGH" && <ErrorIcon stroke={"white"} stroke-width={1} style={{ width: "16px", color: "#DD5B49" }} />}
                                </div>
                              </div>
                            )}
                            {flag == 1 && (
                              <div
                                style={{
                                  justifyContent: "flex-start",
                                  textAlign: "left",
                                  color: "#3D3D3B",
                                  fontSize: "14px",
                                  fontWeight: index === selectIndex ? "600" : "400",
                                  whiteSpace: "nowrap"
                                }}
                              >
                                {category.text}
                              </div>
                            )}
                            {flag == 1 && (
                              <div style={{ marginLeft: "auto" }}>
                                {category.text.toUpperCase() === "DASHBOARD" ||
                                category.text === "Admin Settings" ||
                                category.text.toUpperCase() === "CAMPAIGN HUB" ||
                                category.text.toUpperCase() === "DATA UPLOAD" ? (
                                  <div style={{ width: "1rem", height: "1rem", fontSize: "1.5rem" }}></div>
                                ) : menuIndex == index ? (
                                  <ExpandLessIcon htmlColor="#3D3D3B" style={{ width: "16px", height: "16px", fontSize: "16px" }} />
                                ) : (
                                  <ExpandMoreIcon htmlColor="#3D3D3B" style={{ width: "16px", height: "16px", fontSize: "16px" }} />
                                )}
                              </div>
                            )}
                          </button>
                        </div>

                        {category.key === "RECENTS" && flag == 1 && menuIndex == 0 && renderRecentRoutes(index)}
                        {category.subCategories.length > 0 && menuIndex === index && flag == 1 && (
                          <div
                            style={{
                              width: "-webkit-fill-available",
                              marginTop: "-12px",
                              marginLeft: "10px",
                              marginRight: "10px",
                              paddingTop: "12px",
                              borderRadius: "0 0 12px 12px",
                              // overflow: "hidden",
                              backgroundColor: "#F8F9F7",
                              display: "grid",
                              gap: "2px",
                            }}
                          >
                            {/* <ClickAwayListener onClickAway={()=>handleClickAway()}> */}
                            {category.subCategories
                              .map((subCategory, subInd) => {
                                if(isPermitted(category.value)) return <></>;

                                return (
                                  <>
                                    {subCategory.label == "Dashboard" ||
                                    subCategory.label == "Admin Settings" ||
                                    subCategory.label.toUpperCase() == "CAMPAIGN HUB" ||
                                    subCategory.label.toUpperCase() === "DATA UPLOAD" ? (
                                      <></>
                                    ) : (
                                      <div className="menuList-root" style={{ display: "flex" }}>
                                        <div
                                          key={shortid()}
                                          onClick={() => handleRoutesClick(subCategory, subInd)}
                                          disabled={subCategory.isdisabled}
                                          className="menuList"
                                          style={index === selectIndex && subIndex === subInd ? { backgroundColor: "#F0F2EE" } : {}}
                                        >
                                          <div>
                                            {index === selectIndex && subIndex === subInd ? (
                                              <Divider orientation="vertical" style={{ height: "6px", width: "6px", borderRadius: "50%", backgroundColor: "#78C51C", borderRight: "none" }} />
                                            ) : (
                                              <Divider orientation="vertical" style={{ height: "6px", width: "6px", borderRight: "none" }} />
                                            )}
                                          </div>
                                          <div className="menuListText1" style={index === selectIndex && subIndex === subInd ? { fontWeight: "600", whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}>
                                            {subCategory.label === "Email Generator" ? (
                                              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "stretch", gap: "8px" }}>
                                                <div style={{ whiteSpace: "nowrap" }}>{subCategory.label}</div>
                                                <Badge style={{ paddingLeft: "18px", top: "7px" }} color="secondary" badgeContent="Beta"></Badge>
                                              </div>
                                            ) : (
                                              subCategory.label
                                            )}
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                              {subCategory.warning === "HIGH" && <WarningRoundedIcon stroke={"white"} stroke-width={1} style={{ height: "15px", color: "#FF9800" }} />}
                                              {subCategory.warning === "VERY HIGH" && <ErrorIcon stroke={"white"} stroke-width={1} style={{ height: "15px", color: "#DD5B49" }} />}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              })}
                            {/* </ClickAwayListener> */}
                          </div>
                        )}
                      </>
                    );
                  })}
              </>
            </div>
          </div>
          <div className="header-container-end">
            <Divider style={{ width: "-webkit-fill-available", margin: "12px 14px 12px 14px" }} />
            <div style={{ display: hideIcp, flexDirection: "column", width: "-webkit-fill-available", margin: "0 10px", paddingLeft: "3px" }}>
              <div className="logout-root" style={{ display: "flex", alignItems: "center", gap: "8px", padding: "10px", borderRadius: "12px" }}>
                <div style={{ height: "24px", width: "24px", alignSelf: "baseline" }}>
                  <LogoutIcon style={{ height: "24px", width: "24px" }} />
                </div>
                <div style={{ display: "grid", gap: "4px" }}>
                  <Tooltip title="Logout" followCursor>
                    <div onClick={logOut} style={{ fontSize: "14px", color: "#3D3D3B", cursor: "pointer" }}>
                      Logout
                    </div>
                  </Tooltip>
                  <p style={flag === 1 ? { color: "#848484", fontSize: "10px", fontWeight: "500", whiteSpace: "nowrap" } : { display: "none" }}>
                    Last login: {formattedDate === "Invalid Date" ? "--- --" : formattedDate} {formattedTime === "Invalid Date" ? "--:--" : formattedTime} {timezone}
                  </p>
                </div>
              </div>
              <div style={{ padding: "10px", marginLeft: "-4px" }}>
                <User name={name} email={email} />
              </div>
            </div>

            {flag == 0 && (
              <>
                <Tooltip title="Logout" followCursor>
                  <IconButton variant="text" color="#E31B0C" onClick={logOut} style={{ color: "#B2B2B2", marginTop: "6.5px", marginBottom: "11.5px" }}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
                <div style={{ padding: "10px", marginTop: "5px" }}>
                  <UserAvatar name={name} />
                </div>
              </>
            )}
          </div>
        </div>
      </ThemeProvider>
      <PersonalizedEmail {...{ userDetails, tenantId, icp: props.globalIcp, personalisedEmailDialogOpen, setPersonalisedEmailDialogOpen, personalisedContObj, setPersonalisedContObj }} />
    </>
  );
}

export default Header;
