
import {
    Typography,
  } from "@material-ui/core";
import panda from '../panda.png';
const PandaImage = () => {
    return (
      <div 
      >
          <img style={{
          width: '278.5px',
          height: '223.17px',
          left: '520.25px',
          top: '80.41px'
        }} src={panda} alt="No-Access"/>
      </div>
    )
  }

export const NoAccess=()=>{
    return(
      
            <div id="contentframecustom">
                <PandaImage/>
                
        <div id="textboxcustom">
                <Typography variant="h5" style={{fontFamily: "Inter",
        fontSize: '24px',
        fontWeight: '600',
        lineHeight: '32px',
        letterSpacing: '0px',
        textAlign: 'center',
        color: "#CACACA"
        }}>Looks like you’ve lost your way</Typography>
        <Typography variant="h6" style={{
          fontFamily: "Inter",
          fontSize: '16px',
          fontWeight: '800',
          lineHeight: '36px',
          letterSpacing: '0px',
          textAlign: 'center',
          color: "#CACACA"
          }}>You don’t have access to this page, contact your administrator.</Typography>
            </div></div>
          
    )
}