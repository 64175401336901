import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function ZohoCallback() {

    const history = useHistory();
    const tenantId = localStorage.getItem('b2bTenantId');
    
    useEffect(() => {
        const code = getParameterByName('code');
        const location = getParameterByName('location');
        if (code) {
            fetch(`${window._env_.ZOHO_BULK_SYNC}/zoho/auth/callback?code=${code}&tenantId=${tenantId}&location=${location}`, {
                method: 'POST',
                credentials : 'include',
                headers: {
                    "auth-token" : `${localStorage.getItem('b2bToken')}`
                }
            }).then(res => {
                history.push('/zoho-sync');
            })
        }
    });

    return (
        <p>Zoho Autenticated</p>
    )
}

export default ZohoCallback;
// lt:3000
// developer console
// change local storage key in line 14