import React from "react";
import {
  InputBase,
  Paper,
  IconButton,
  Avatar,
  Button,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core";

function SalesforceSetUp() {
  let defaultURLValue = "login.salesforce.com";
  const [value, setValue] = React.useState(defaultURLValue);
  const [isCustomURL, setIsCustomURL] = React.useState(false);

  if (isCustomURL === false && value != defaultURLValue)
    setValue(defaultURLValue);

  
  if (isCustomURL === true && value == defaultURLValue)
    setValue("");

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#1f454f",
      },
    },
  });

  function setTenantConfig() {
    const tenantId = localStorage.getItem("b2bTenantId");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      tenantId: tenantId,
      tool: "Salesforce",
      configArray: [
        {
          tenantConfigKey: "Custom Domain",
          tenantConfigValue: value,
        },
      ],
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
      credentials: "include",
    };

    const tenantConfigUrl = `${window._env_.TENANT_CONFIG_API}`;
    fetch(tenantConfigUrl + "/tenant/set-tenant-config", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
  }

  function addSource() {
    let url = `https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=${window._env_.SALESFORCE_CLIENT_ID}&redirect_uri=${window._env_.TEMP_SALESFORCE_BULK_SYNC}/salesforce/auth/redirect`;
    //  url = encodeURIComponent(url);
    window.open(url, "_self");
    // alert(url)
  }

  const changeHandler = (event) => {
    setValue(event.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="zoho-position">
        <form>
        <br></br>

        
          <input id="customurl"
            type="checkbox"
            onChange={(e) => setIsCustomURL(e.target.checked)}
            style={{ marginTop: "0px", marginLeft: "20px" }}
          ></input>
         <label for="customurl">Use Saleforce custom URL </label>

          <br></br>
          
          <input
            type="text"
            style={{ marginTop: "0px", marginLeft: "20px" }}
            onChange={changeHandler}
            value={value}
            disabled={!isCustomURL}
          />

        <br></br>
        <br></br>
          
          <Button
            size="small"
            disabled={!value}
            variant="contained"
            onClick={() => {
              setTenantConfig();
              addSource();
            }}
            color="secondary"
            style={{ marginLeft: "20px" }}
          >
            Authenticate Salesforce
          </Button>

         
          
        </form>
      </div>
    </ThemeProvider>
    // onClick={logOut}
  );
}
export default SalesforceSetUp;
