import React from 'react';
import { InputBase, Paper, IconButton, Avatar, Button, ThemeProvider, createMuiTheme } from "@material-ui/core";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function ZohoSync() {
  const history = useHistory();
  const theme = createMuiTheme({
    palette:{
      secondary:{
        main:"#1f454f"
      }
    }
  })
    function syncData() {
      //  toast('Bulk Sync Started..!')
      const tenantId = localStorage.getItem('b2bTenantId');
      const url = `${window._env_.ZOHO_BULK_SYNC}/zoho/zoho-crm/bulk-id?tenantId=${tenantId}`;
      fetch(url, {credentials : 'include'})
      .then(res => res.json())
      .catch(err => {
        console.log(err);
      })
      // url = encodeURIComponent(url);
      // window.open(url, '_self');
      history.push('/');
    }
    return (
      <ThemeProvider theme={theme}>
        <div className="zoho-position">
          <Button size="small" variant="contained" onClick={syncData} color="secondary" style={{marginLeft:"20px"}}>Bulk Sync Zoho</Button>
        </div>
        <ToastContainer />
      </ThemeProvider>
      // onClick={logOut}
    );
}

export default ZohoSync;
